import * as React from "react";
import { Nav, Banner } from "../../components";
import "./Clients.scss";
export function Clients() {
  return (
    <div>
      <header>
        <Nav />
        <Banner pic={3} />
      </header>
      <div className="clients-wrapper">
        <div className="clients">
          <div className="clients__form">
            <div className="clients__form--title">
              <h1>Book a Demonstration</h1>
            </div>
            <form
              action="https://formsubmit.co/drabikbenjamin@gmail.com"
              method="POST"
            >
              {/* drabikbenjamin@gmail.com */}
              <label>First and Last Name</label>
              <input type="text" name="name"></input>

              <label>Your Business</label>
              <input type="text" name="business"></input>

              <label>Email Address</label>
              <input type="email" name="email"></input>

              <label>Phone Number</label>
              <input type="phone" name="phone"></input>

              <button>
                <p>Submit</p>
              </button>
            </form>
          </div>
          <div className="clients__imgbox">
            <img
              style={{ width: "500px" }}
              src="https://www.atyourbusiness.com/blog/wp-content/uploads/2019/05/happyoffice-1024x682.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
