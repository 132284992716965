import * as React from "react";
import { Nav, Banner } from "../../components";

export function Insights() {
  return (
    <div>
      <Nav />
      <Banner pic={4} />
    </div>
  );
}
