import * as React from "react";
import { useNavigate } from "react-router";
import "./Footer.scss";
export function Footer() {
  const Navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__top__left">
          <h1>Fair Chance</h1>
          {/* <h4>1-555-555-5555</h4>
          <p>42 Wallabe Way</p> */}
          {/* <p>Salt Lak</p> */}
          {/* <p>84104</p> */}
        </div>
        <div className="footer__top__divider"></div>
        <div className="footer__top__right">
          <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/about")}>About</p>
          </div>
          {/* <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/services")}>Services</p>
          </div> */}
          {/* <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/resources")}>Resources</p>
          </div> */}
          {/* <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/insights")}>Insights</p>
          </div> */}
          {/* <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/careers")}>Careers</p>
          </div> */}
          <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/faq")}>FAQ</p>
          </div>
          <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/clients")}>Clients</p>
          </div>
          <div className="footer__top__right--navtag">
            <p onClick={() => Navigate("/")}>Make a Payment</p>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        {/* <div className="footer__bottom__logos">
          <img src="https://placehold.jp/100x100.png" />
          <img src="https://placehold.jp/100x100.png" />
          <p>[logos]</p>
          <img src="https://placehold.jp/100x100.png" />
          <img src="https://placehold.jp/100x100.png" />
        </div> */}
        <div className="footer__bottom--copyright">
          <p>© 2024 Fair Chance Financial</p>
        </div>
      </div>
    </div>
  );
}
