import React, { useState } from "react";
import { Nav, Banner } from "../../components";
import "./Faq.scss";

const faqArray = [
  {
    id: 1,
    question: "What is Fair Chance Financial and what services do you provide?",
    answer:
      "Fair Chance Financial is a debt collection agency that helps creditors recover outstanding debts. We offer professional and ethical debt collection services, focusing on maintaining positive relationships between creditors and debtors while ensuring compliance with all relevant laws and regulations.",
  },
  {
    id: 2,
    question: "How did Fair Chance Financial get my information?",
    answer:
      "We received your information from a creditor to whom you owe a debt. Our role is to assist in the recovery of this outstanding debt on their behalf.",
  },
  {
    id: 3,
    question: "Is Fair Chance Financial a legitimate company?",
    answer:
      "Yes, Fair Chance Financial is a fully licensed and accredited debt collection agency. We adhere to all federal and state laws, including the Fair Debt Collection Practices Act (FDCPA).",
  },
  {
    id: 4,
    question: "How can I verify the debt you are trying to collect?",
    answer:
      "You can request a validation of the debt in writing. We will provide you with detailed information about the debt, including the original creditor, the amount owed, and any supporting documentation.",
  },
  {
    id: 5,
    question: "What payment options do you offer?",
    answer:
      "We offer several payment options to make it convenient for you. These include online payments, phone payments, checks, money orders, and installment plans. Our team will work with you to find a suitable payment arrangement.",
  },
  {
    id: 6,
    question: "Can I negotiate the amount of debt I owe?",
    answer:
      "In some cases, we may be able to negotiate a settlement amount that is less than the full balance. Please contact our office to discuss your financial situation and explore potential settlement options.",
  },
  {
    id: 7,
    question: "What happens if I cannot pay the debt in full immediately?",
    answer:
      "If you are unable to pay the debt in full, we can set up a payment plan that fits your budget. Our goal is to help you manage your debt responsibly and avoid further financial difficulties.",
  },
  {
    id: 8,
    question: "How will paying off this debt affect my credit score?",
    answer:
      "Paying off a debt can positively impact your credit score by showing that you have resolved the outstanding obligation. It may also prevent further negative reporting to credit bureaus.",
  },
  {
    id: 9,
    question:
      "What should I do if I believe the debt is not mine or is incorrect?",
    answer:
      "If you believe the debt is not yours or there is an error, you should dispute the debt in writing. Provide any supporting evidence, and we will investigate the matter thoroughly and provide a response.",
  },
  {
    id: 10,
    question: "How do you protect my personal information?",
    answer:
      "Fair Chance Financial takes the privacy and security of your personal information very seriously. We use industry-standard security measures to protect your data and comply with all relevant privacy laws and regulations.",
  },
];

export function Faq() {
  const [open, setOpen] = useState(-1);

  return (
    <div>
      <header>
        <Nav />
        <Banner pic={6} />
      </header>
      <div className="faq-wrapper">
        <div className="faq">
          <div className="faq__heading">
            <div className="faq__heading--title">
              <h4>Frequently Asked Questions</h4>
            </div>
            <div className="faq__heading--divider"></div>
            <div className="faq__heading--content">
              <p>
                Here are commonly asked questions about your balance or the
                collection process.
              </p>
            </div>
            {/* <div className="faq__heading--divider"></div> */}
          </div>
          <div className="faq__section">
            {faqArray.map((question) => {
              return (
                <div
                  className="faq__section__subsection"
                  key={question.id}
                  onClick={() => {
                    if (open === question.id) {
                      setOpen(-1);
                    } else {
                      setOpen(question.id);
                    }
                  }}
                >
                  <div className="faq__section__subsection--title">
                    <h4>{question.question}</h4>
                  </div>
                  <div className="faq__section__subsection--divider"></div>
                  <div
                    className={
                      open === question.id
                        ? "faq__section__content--open"
                        : "faq__section__content"
                    }
                  >
                    <div style={{ overflow: "hidden" }}>
                      <p>{question.answer}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
