import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import bannerOne from "../../assets/banners/callerwithscript.jpg";
import bannerTwo from "../../assets/banners/singlecaller.jpg";
import "./Banner.scss";
export function Banner(props) {
  const Navigate = useNavigate();

  let picNum = props.pic;

  if (picNum === 1) {
    return (
      <div className="banner">
        <div className="banner__titlebox">
          <div>
            <h1>Take Control</h1>
            <h2>Increase your revenue with Fair Chance Financial</h2>
          </div>
          <div>
            <button onClick={() => Navigate("/clients")}>
              <p>BOOK A DEMO</p>
            </button>
          </div>
        </div>
      </div>
    );
  } else if (picNum === 2) {
    return (
      <div className="bannerAbout">
        <div className="bannerAbout__titlebox">
          <div>
            <h1>About Us</h1>
          </div>
        </div>
      </div>
    );
  } else if (picNum === 3) {
    return (
      <div className="bannerClient">
        <div className="bannerClient__titlebox">
          <div>
            <h1>Experience</h1>
          </div>
        </div>
      </div>
    );
  } else if (picNum === 4) {
    return (
      <div className="bannerBlog">
        <div className="bannerBlog__titlebox">
          <div>
            <h1>Insights & News</h1>
          </div>
        </div>
      </div>
    );
  } else if (picNum === 5) {
    return (
      <div className="bannerCareers">
        <div className="bannerCareers__titlebox">
          <div>
            <h1>Careers</h1>
          </div>
        </div>
      </div>
    );
  } else if (picNum === 6) {
    return (
      <div className="bannerFaq">
        <div className="bannerFaq__titlebox">
          <div>
            <h1>FAQ</h1>
          </div>
        </div>
      </div>
    );
  }
}
