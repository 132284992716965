import * as React from "react";
import { Nav, Banner } from "../../components";

export function Careers() {
  return (
    <div>
      <Nav />
      <Banner pic={5} />
    </div>
  );
}
