import * as React from "react";
import { useNavigate } from "react-router";
import "./Differences.scss";

export function Differences() {
  const Navigate = useNavigate();
  return (
    <div className="differences-wrapper">
      <div className="differences">
        <div
          className="differences__section1"
          onClick={() => Navigate("/clients")}
        >
          <h1>Consumer Retention</h1>
          <h2>Learn More</h2>
        </div>
        <div
          className="differences__section2"
          onClick={() => Navigate("/clients")}
        >
          <h1>No Hidden Fees</h1>
          <h2>Learn More</h2>
        </div>
        <div
          className="differences__section3"
          onClick={() => Navigate("/clients")}
        >
          <h1>Legal Power</h1>
          <h2>Learn More</h2>
        </div>
      </div>
    </div>
  );
}
