import * as React from "react";
import {
  Nav,
  Banner,
  Scrollbox,
  Carousel,
  Differences,
} from "../../components";
import "./Home.scss";
export function Home() {
  return (
    <div>
      <header>
        <Nav />
        <Banner pic={1} />
      </header>
      <div>
        <div className="home">
          <div className="home__left">
            <div>{/* <img src="https://placehold.jp/100x100.png" /> */}</div>

            <h1>Our Goal</h1>
            <p>
              Our goal is to provide compassionate, ethical, and effective
              collection solutions that foster trust, preserve relationships,
              and deliver positive outcomes for our clients and their consumers.
            </p>
            <button>
              <a href="/about">Learn More</a>
            </button>
          </div>
          <div className="home__right">
            <h1>Our Services</h1>

            <p>
              Join our many satisfied clients! Review their testimonials and
              sign up for our services now!
            </p>
            <button>
              <a href="/clients">Learn More</a>
            </button>
          </div>
        </div>
        {/* <Banner /> */}

        <div className="reviews">
          <div className="reviews__container">
            <Scrollbox />
            <Carousel />
          </div>
        </div>
      </div>
      <Differences />
    </div>
  );
}
