import React, { useEffect, useState } from "react";
import "./Carousel.scss";

export function Carousel() {
  const [activeSlide, setActiveSlide] = useState(1);

  useEffect(() => {
    // This variable prevents race condition
    let current = 1;
    const cycleReviews = () => {
      if (current === 5) {
        current = 1;
      } else {
        current += 1;
      }
      setActiveSlide(current);
    };
    // intervalId identified so it can be canceled on unmount
    const intervalId = setInterval(() => {
      cycleReviews();
    }, 10000);
    // Removes interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const reviews = [
    {
      name: "Jonathan D.",
      quote:
        "I was initially nervous about speaking with a debt collector, but the team at Fair Chance Financial was understanding and respectful. They listened to my situation, offered flexible repayment options, and treated me with dignity. Thank you for making a stressful experience much more bearable.",
    },
    {
      name: "Peter C.",
      quote:
        "Fair Chance Financial's agents were courteous, non-judgmental, and supportive throughout our interactions. They were willing to answer my questions and address my concerns, which made the debt repayment process less intimidating. I'm grateful for their patience and assistance.",
    },
    {
      name: "Paulette H.",
      quote:
        "I had a positive experience working with Fair Chance Financial's agents. Despite my financial challenges, they were compassionate and offered constructive solutions to help me resolve my debt. Their respectful approach made a difficult situation easier to navigate.",
    },
    {
      name: "Ryan W.",
      quote:
        "The agents at Fair Chance Financial were professional, patient, and helpful. They treated me with respect and worked collaboratively with me to find a solution that fit my budget. I was impressed by their dedication to customer service and their willingness to assist me in resolving my debt.",
    },
    {
      name: "Kevin R.",
      quote:
        "I appreciate the professionalism and empathy shown by Fair Chance Financial's collectors. They took the time to explain my options clearly and worked with me to create a manageable repayment plan. It was a relief to work with a company that values communication and understanding.",
    },
  ];

  return (
    <div className="caro-body">
      <div className="caro-body__title">
        <h1>Consumer Reviews</h1>
      </div>
      <div className="caro-body__divider"></div>
      <ul className="carousel__list">
        {reviews.map((review, index) => {
          const { name, quote } = review;
          const count = index + 1;
          return (
            <li
              className={`carousel__item
                ${count === activeSlide ? " active" : ""}
                ${count < activeSlide ? " left" : ""}
                ${count > activeSlide ? " right" : ""}
              `}
              key={count}
              onClick={() => {
                if (activeSlide < 5) {
                  setActiveSlide(activeSlide + 1);
                } else {
                  setActiveSlide(1);
                }
              }}
            >
              <blockquote className="carousel__quote">
                <cite>
                  <span className="carousel__name">{name}</span>
                </cite>
                <p>"{quote}"</p>
              </blockquote>
            </li>
          );
        })}
        <li className="carousel__indicator">
          <span
            className={`carousel__dot${activeSlide === 1 ? " active" : ""}`}
          />
          <span
            className={`carousel__dot${activeSlide === 2 ? " active" : ""}`}
          />
          <span
            className={`carousel__dot${activeSlide === 3 ? " active" : ""}`}
          />
          <span
            className={`carousel__dot${activeSlide === 4 ? " active" : ""}`}
          />
          <span
            className={`carousel__dot${activeSlide === 5 ? " active" : ""}`}
          />
        </li>
      </ul>
      {/* <p>Active Slide = {activeSlide}</p> */}
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
