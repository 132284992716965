import * as React from "react";

import "./Scrollbox.scss";

export function Scrollbox() {
  return (
    <div className="scroll-body">
      <div className="scroll-body__title">
        <h1>Hear From Our Clients</h1>
      </div>
      <div className="scroll-body__divider"></div>
      <div className="scrollbox">
        <div className="scrollbox__comment">
          <div className="scrollbox__comment--title">
            <h4>Glenn Cove Apartments</h4>
          </div>
          <div className="scrollbox__comment--content">
            <p>
              Fair Chance Financial has been instrumental in helping us manage
              our outstanding debts. Their exceptional customer service and high
              success rate have exceeded our expectations. We highly recommend
              their services to any business looking for reliable debt
              collection solutions.
            </p>
          </div>
        </div>
        <div className="scrollbox__comment">
          <div className="scrollbox__comment--title">
            <h4>Crossing Creek</h4>
          </div>
          <div className="scrollbox__comment--content">
            <p>
              Choosing Fair Chance Financial was one of the best decisions we
              made for our company. Their professional team not only recovered
              our debts efficiently but also maintained positive relationships
              with our consumers. Their commitment to excellence is truly
              commendable!
            </p>
          </div>
        </div>
        <div className="scrollbox__comment">
          <div className="scrollbox__comment--title">
            <h4>Sheryl from Alpine Housing</h4>
          </div>
          <div className="scrollbox__comment--content">
            <p>
              We've been working with Fair Chance Financial for some time now,
              and we're consistently impressed by their dedication and results.
              Their personalized approach to debt collection has helped us
              retain our consumers while recovering overdue payments. We
              couldn't be happier with their services!
            </p>
          </div>
        </div>
        <div className="scrollbox__comment">
          <div className="scrollbox__comment--title">
            <h4>Southern Glen Housing</h4>
          </div>
          <div className="scrollbox__comment--content">
            <p>
              Fair Chance Financial stands out for their outstanding customer
              service and impressive success rate. They have a unique ability to
              handle sensitive situations with empathy and professionalism,
              ensuring both our interests and our consumers' needs are met.
              We're grateful for their partnership and highly recommend them to
              other businesses.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
