import * as React from "react";
import { Nav, Banner } from "../../components";

export function Services() {
  return (
    <div>
      <Nav />
      <Banner />
    </div>
  );
}
