import { AppLayout } from "./layouts";
import "./App.scss";
import { Ender, Footer } from "./components";
function App() {
  return (
    <div className="App">
      <AppLayout />
      <Ender />
      <Footer />
    </div>
  );
}

export default App;
