import * as React from "react";
import { Route, Routes } from "react-router";
import {
  Home,
  About,
  Careers,
  Clients,
  Insights,
  Resources,
  Services,
  Faq,
} from "../routes";
export function AppLayout() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/services" element={<Services />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </div>
  );
}
