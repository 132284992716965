import * as React from "react";
import { useNavigate } from "react-router";
import "./Nav.scss";
export function Nav() {
  const Navigate = useNavigate();
  //   const navClick = (page) => {
  //     console.log(page);
  //     Navigate(`/${page}`);
  //   };
  return (
    <div className="NavBar">
      <div className="NavBar__logoAndTitle">
        <h1 onClick={() => Navigate("/")}>Fair Chance</h1>
      </div>

      <nav className="nav">
        <button onClick={() => Navigate("/about")}>About Us</button>
        {/* <button onClick={() => Navigate("/services")}>Services</button> */}
        {/* <button onClick={() => Navigate("/resources")}>Resources</button> */}
        {/* <button onClick={() => Navigate("/insights")}>Insights</button> */}
        {/* <button onClick={() => Navigate("/careers")}>Careers</button> */}
        <button onClick={() => Navigate("/clients")}>Clients</button>
        <button onClick={() => Navigate("/faq")}>FAQ</button>
        <button>Make a Payment</button>
      </nav>
    </div>
  );
}
