import * as React from "react";
import "./Ender.scss";
import { useNavigate } from "react-router";
export function Ender() {
  const Navigate = useNavigate();
  return (
    <div className="ender-wrapper">
      <div className="ender">
        <div className="ender__titlebox">
          <div>
            <h1>See the Difference</h1>
            {/* <h2>MAKE THEM WISH THEY WERE DEAD</h2> */}
          </div>
        </div>
        <div className="ender__booking">
          <div>{/* <img src="https://placehold.jp/200x200.png" /> */}</div>
          <button onClick={() => Navigate("/clients")}>
            <p>BOOK A DEMO</p>
          </button>
        </div>
      </div>
    </div>
  );
}
