import * as React from "react";
import { Nav, Banner } from "../../components";
import "./About.scss";
export function About() {
  return (
    <div>
      <header>
        <Nav />
        <Banner pic={2} />
      </header>
      <div className="about-wrapper">
        <div className="about">
          <div className="about__heading">
            <div className="about__heading--title">
              <h4>About Fair Chance</h4>
            </div>

            <div className="about__heading--content">
              <p>
                At Fair Chance Financial, we understand that managing debt can
                be a challenging and stressful experience. With years of
                expertise in the financial industry, we are dedicated to
                providing compassionate and ethical debt collection solutions
                tailored to our clients' unique needs. Our team of professionals
                works diligently to establish fair and transparent communication
                between debtors and creditors, aiming to find mutually
                beneficial resolutions. Committed to integrity and
                professionalism, Fair Chance Financial strives to offer a fresh
                perspective on debt collection, focusing on fairness, respect,
                and positive outcomes for all parties involved.
              </p>
            </div>
            <div className="about__heading--divider"></div>
          </div>
          <div className="about__section">
            <div className="about__section__subsectionPic">
              <div className="about__section--content">
                <img src="https://content.gallup.com/origin/gallupinc/GallupSpaces/Production/Cms/WORKPLACEV9CMS/fumuc5txt0gkg3lu-qmnxa.jpg" />
              </div>
            </div>
            <div className="about__section__subsection">
              <div className="about__section__subsection--title">
                <h4>We Care</h4>
              </div>
              <div className="about__section__subsection--divider"></div>
              <div className="about__section--content">
                <p>
                  Our proactive and compassionate approach not only facilitates
                  debt repayment but also enhances consumer loyalty and
                  satisfaction. Through Fair Chance Financial's tailored debt
                  collection solutions, our clients can maintain positive
                  relationships with their consumers, ultimately fostering
                  loyalty and long-term retention.
                </p>
              </div>
            </div>
          </div>
          <div className="about__section">
            <div className="about__section__subsection">
              <div className="about__section__subsection--title">
                <h4>Retention</h4>
              </div>
              <div className="about__section__subsection--divider"></div>
              <div className="about__section--content">
                <p>
                  At Fair Chance Financial, we believe in building long-term
                  relationships and fostering trust between our clients and
                  their consumers. Our approach to debt collection goes beyond
                  mere recovery; we focus on preserving the consumer-business
                  relationship by employing empathetic and personalized
                  communication strategies. By treating each consumer with
                  respect and understanding their unique financial
                  circumstances, we aim to turn challenging situations into
                  opportunities for mutual understanding and collaboration.
                </p>
              </div>
            </div>
            <div className="about__section__subsectionPic">
              <div className="about__section--content">
                <img src="https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/O2019-Hero-image-x2:VP1-539x440?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=1920&hei=720&qlt=75&fit=constrain" />
              </div>
            </div>
          </div>
          <div className="about__section">
            <div className="about__section__subsectionPic">
              <div className="about__section--content">
                {/* <img src="https://placehold.jp/400x400.png" /> */}
                <img src="https://d1pbny5bq445o3.cloudfront.net/corporate-grubhub/wp-content/uploads/2022/05/02141237/GettyImages-1342255924-scaled.jpg" />
              </div>
            </div>
            <div className="about__section__subsection">
              <div className="about__section__subsection--title">
                <h4>Why Fair Chance?</h4>
              </div>
              <div className="about__section__subsection--divider"></div>
              <div className="about__section--content">
                <p>
                  Clients choose Fair Chance Financial for our exceptional
                  customer service and proven success rate in debt collection.
                  Our dedicated team prioritizes clear communication,
                  professionalism, and empathy in every interaction, ensuring
                  that both clients and consumers feel valued and understood.
                  With a track record of delivering effective and efficient debt
                  recovery solutions, we have earned the trust and confidence of
                  our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
